.hero {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    color: white;
}

.hero-video-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.hero-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover; /* Ensures the video covers the area without distortion */
    z-index: -1; /* Sends the video behind any content */
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Keeps a darkened backdrop for readability */
    z-index: 1; /* Overlay is behind the text but above the video */
}

.hero-content {
    position: relative;
    z-index: 3; /* Set z-index higher than .hero-overlay and .hero-video */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; /* Centers the content both vertically and horizontally */
    text-align: center;
    color: white; /* Ensures the text color contrasts against the background */
}
.hero-title, .hero-subtitle {
    z-index: 2; /* Explicitly sets these elements above the overlay */
    position: relative; /* Positions relative to inherit the stacking context */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Improves text readability */
}


.hero-title {
    z-index: 2;
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.hero-subtitle {
    z-index: 2;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.hero-buttons {
    margin-top: 2rem;
}

/* Scroll Indicator */
.scroll-indicator {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    text-align: center;
    color: white;
    animation: bounce 2s infinite;
}

.scroll-arrow {
    width: 30px;
    height: 30px;
    margin: 0 auto 5px;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
    transform: rotate(45deg);
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero-title {
        font-size: 2.5rem;
    }

    .hero-subtitle {
        font-size: 1.2rem;
    }

    .hero-buttons .btn {
        margin: 0.5rem;
    }
}

/* Button Hover Effects */
.btn-primary {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.btn-outline-light {
    transition: all 0.3s ease;
}

.btn-outline-light:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
}

/* Optional: Video Fallback */
.hero-video-fallback {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/mountain-fallback.jpg'); /* Add a fallback image */
    background-size: cover;
    background-position: center;
    display: none;
}

.no-video .hero-video-fallback {
    display: block;
}

.no-video .hero-video {
    display: none;
}